<template>
    <div>

         <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：客户欠款利息</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :sm="12" :md="9" v-if="false">
                                    <el-form-item label="开单日期:">
                                        <el-date-picker
                                                v-model="pickerMonthRange"
                                                style=" margin-top: 8px;padding-top: 2px; width: 200px"
                                                type="monthrange"
                                                align="right"
                                                size="mini"
                                                @change="$_pickerMonthRangeEvent()"
                                                unlink-panels
                                                range-separator="至"
                                                start-placeholder="开始月份"
                                                end-placeholder="结束月份"
                                                :picker-options="pickerOptions">
                                        </el-date-picker>
                                        <el-date-picker
                                                style=" margin-top: 8px;width: 220px"
                                                v-model="pickerDate"
                                                type="daterange"
                                                size="mini"
                                                :clearable="false"
                                                :picker-options="pickerOptions"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                align="right">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :md="3" v-if="false">
                                    <el-input size="mini" style="margin-right: 8px;margin-top: 8px " v-model="formData.date_count">
                                        <template slot="prepend">逾期≥</template>
                                        <template slot="append">天</template>
                                    </el-input>
                                </el-col>
                                <el-col :md="4">
                                    <el-button style="margin-top: 8px; " type="primary" round size="mini" @click="searchList">搜索查询</el-button>
                                    <vxe-toolbar class="pull-left" style=" height: 42px; " import custom export ref="xToolbar2">
                                    </vxe-toolbar>
                                </el-col>
                                <el-col :md="5">
                                    <el-form-item label="结算日期:">
                                        <el-date-picker
                                                style=" margin-top: 8px;width: 220px"
                                                v-model="pickerDateCash"
                                                type="daterange"
                                                size="mini"
                                                :clearable="false"
                                                :picker-options="pickerOptions"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                align="right">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="24">
                            <vxe-grid ref="xGrid"
                                      align="right"
                                      size="mini"
                                      :import-config="{
                                        remote: true,
                                        importMethod: this.importMethod,
                                        modes: ['insert'],
                                        type: ['xlsx'],
                                        types: ['xlsx']
                                     }"
                                      :export-config="{type: ['xlsx'] ,types: ['xlsx'],isFooter:true,isMerge:true}"
                                      border
                                      resizable
                                      show-overflow
                                      keep-source
                                      height="650"
                                      highlight-current-row
                                      :print-config="{}"
                                      :data="tableDataMain"
                                      :columns="tableColumn"
                                      show-footer
                                      :footer-method="footerMethod"
                                      :mouse-config="{selected: true}"
                                      :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: false}"
                                      :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                                <template #interest_note_edit="{ row}">
                                    <vxe-input v-model="row.interest_note"
                                               @blur="remarkEvent(row)"></vxe-input>
                                </template>
                                <template #pager>
                                    <vxe-pager
                                            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                            :current-page.sync="tablePage.currentPage"
                                            :page-size.sync="tablePage.pageSize"
                                            :page-sizes.sync="tablePage.pageSizes"
                                            :total="tablePage.total"
                                            @page-change="handlePageChange">
                                    </vxe-pager>
                                </template>
                            </vxe-grid>
                        </el-col>
                    </el-row>
                </el-form>
        </el-main>
        <el-dialog width="1220px"  :title="isErrorTitle ? '失败' : '导入数据'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <vxe-grid
                    border
                    resizable
                    show-overflow
                    height="400"
                    ref="xGridImport"
                    :columns="tableColumnImport"
                    :data="tableDataImport"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div slot="footer" class="dialog-footer">
                <el-button @click="openExportEvent = false">导 出</el-button>
                <template v-if="isErrorTitle">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </template>

                <el-button type="primary" @click="saveImport" v-show="!isErrorTitle">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear() -1;
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                formData:{
                    //date_count: 7,//逾期天数
                    bill_id_cash_id: '',//唯一ID
                    interest_note: '',//利息备注
                },
                pageType:'客户信息',
                changeCustomerNameDialogFormVisible:false,
                uuid:'',//导入的uuid
                isErrorTitle:'',
                account:[],
                pickerMonthRange: [startDate, new Date()],
                pickerDate: [startDate, new Date()],
                pickerDateCash: [startDate, new Date()],
                tableColumn: [
                    {field:'bill_id_cash_id', width: 80 ,title: '唯一ID' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'customer', width: 280 ,title: '客户名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'total_amount', width: 120 ,title: '金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                       }
                   },
                    {field:'only_bill_date', width: 100 ,title: '订货单日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'only_bill_id', width: 170 ,title: '销售开单-单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'sales_man', width: 70 ,title: '业务员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                       }
                   },
                    {field:'cash_date', width: 100 ,title: '结算日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'cash_id', width: 120 ,title: '出纳单唯一单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'date_count', width: 100 ,title: '逾期天数' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'interest_money', width: 100 ,title: '利息金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {field:'sum', width: 100 ,title: '合计利息' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'interest_note',
                        title: '利息备注' ,
                        editRender: {autofocus: 'input.vxe-input--inner'},
                        slots:{
                            edit: 'interest_note_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/interestMoney/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                ],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    },  {
                        text: '本月',
                        onClick(picker) {

                            picker.$emit('pick', [startDate, now]);
                        }
                    },{
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if(month==0){
                                month = 12;
                                year = year-1;
                            }
                            if(month<10){
                                month = '0'+month;
                            }
                            var myDate = new Date(year,month,0);
                            // 上个月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year+'-'+month+'-'+myDate.getDate()+' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                        }
                    }]
                },
                tableColumnImport:[
                    { field:'bill_id_cash_id', width: 220 ,title: '唯一ID'},
                    { field:'interest_note', title: '利息备注'},
                    ],
                tableDataImport:[],
                tableDataMain:[],
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [50,500,1000,2000,20000]
               },
                filterData: {},
                sortData: {},
                activeName:'first',
           }
       },
        methods: {
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
                })
                return count ? this.$XEUtils.round(count, 2) : 0
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                    } else {
                        if (column.property == 'interest_money' || column.property == 'sum') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                        } else {
                            sums.push('')
                        }
                    }
                })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            remarkEvent(row) {
                //console.log(row.bill_id_cash_id)
                //console.log(row.interest_note)
                this.formData.bill_id_cash_id = row.bill_id_cash_id;
                this.formData.interest_note = row.interest_note;
                this.$axios({
                    method: 'post',
                    url: '/admin/interestMoney/changeInvoiceNote',
                    data: {
                        formData: this.formData,
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },

            openExportEvent () {
                this.$refs.xGridImport.openExport()
           },

            searchList(){
                this.tablePage.currentPage = 1;
                this.getList();
           },
            getList(){
                this.$axios({
                    method:'POST',
                    url:'/admin/interestMoney/search',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                        pickerDate:this.pickerDate,
                        pickerDateCash:this.pickerDateCash,
                        formData:this.formData
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            saveImport(){
                const loading = this.$loading({
                    lock: true,
                    text: '数据录入中，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method:'post',
                    url:'/admin/interestMoney/saveImportExcel',
                    params: {
                        uuid:this.uuid
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
                    this.getList();
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            downFileEvent(){
                window.open('/template/Customers.xlsx','_blank')
           },
            importMethod ({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method:'post',
                    url:'/admin/interestMoney/importExcel',
                    data: formBody,
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.isErrorTitle=response.data.isError
                        if(response.data.isError == true){
                            this.tableDataImport=response.data.remarksListError
                        }else{
                            this.tableDataImport=response.data.interestRemarksList
                        }
                        this.uuid=response.data.uuid
                        this.dialogFormVisible = true;

                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            handlePageChange ({ currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
           },
       },
        created () {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2);
                this.getList();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    /deep/ .el-form-item__error{
        position: initial !important;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
    /deep/ .vxe-input--panel{
        position: fixed !important;
        left:auto;
   }
    /deep/ .el-input-number .el-input__inner{
        text-align: initial !important;
   }

    .el-form-item__content .el-input-group{
        vertical-align: initial;
   }
</style>
